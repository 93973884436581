import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
import BasicTextModule from "../components/BasicTextModule/BasicTextModule"
import PerksModule from "../components/PerksModule/PerksModule"
import Perk from "../components/PerksModule/Perk"
import Features from "../components/Features/Features"
import LatestPosts from "../components/Post/LatestPosts"

const Index = () => {
  return (
    <>
      <Seo title="Home" />
      <Layout>
        <BannerModule
          title="Welkom bij De Houtwerkerij"
          subTitle="Ontstaan uit passie, gedreven door ambitie."
        />
        
        <Features
          title="Enkele projecten van De Houtwerkerij."
          introduction="Hier vindt u enkele voorbeelden van projecten gemaakt door mij."
        />
        <LatestPosts
          title="Laatste nieuws"
          introduction="Hier vindt u de laatste nieuwsberichten."
        />
      </Layout>
    </>
  )
}

export default Index
